<script>
    let id = "";
    let password = "";

    function download() {
        window.location = `${window.origin}/d/${id}/${password}`;
    }
</script>

<h1><i class="fas fa-file-download"></i>&nbsp;&nbsp;Download</h1>

<div class="delete">
    <label for="id">ID</label>
    <input bind:value={id} id="id" placeholder="File ID" type="text" />

    <label for="password">Password</label>
    <input
        bind:value={password}
        id="password"
        placeholder="File password"
        type="text"
    />

    <button on:click={download}>Download</button>
</div>

<style type="text/scss">h1 {
  color: #ee8572;
}

div.delete {
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 1em;
  align-content: center;
  align-items: center;
}
div.delete label {
  color: #ee8572;
  font-weight: bold;
}
div.delete input {
  padding: 1em;
  background-color: #ddd;
  width: auto;
  display: block;
  border: none;
  color: #35495e;
  outline: none;
  font-size: 12pt;
}
div.delete button {
  width: 100%;
  background-color: #ee8572;
  color: #35495e;
  border: none;
  padding: 1em;
  font-size: 12pt;
  outline: none;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  grid-column-start: 1;
  grid-column-end: 3;
  font-weight: bold;
}
div.delete button:hover:not(:disabled) {
  background-color: rgb(232.5126582278, 93.6202531646, 68.4873417722);
}
div.delete button:disabled {
  background-color: rgb(232.5126582278, 93.6202531646, 68.4873417722);
  cursor: initial;
}
div.delete p.error {
  text-align: center;
  background-color: #f33;
  padding: 1em;
  color: #35495e;
}

/*# sourceMappingURL=Download.svelte.css.map */</style>
