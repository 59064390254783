<script>
    import { createEventDispatcher } from "svelte";
    export let tab;

    const dispatch = createEventDispatcher();

    function setTab(tab) {
        dispatch("setTab", tab);
    }
</script>

<header>
    <div class="header-container">
        <h1>
            <a href="/"> <i class="fas fa-user-ninja"></i> Tempfiles</a>
        </h1>
        <div class="buttons">
            <button
                on:click={() => setTab("upload")}
                class={tab === "upload" ? "selected" : ""}
                ><i class="fas fa-file-upload"></i> Upload</button
            >
            <button
                on:click={() => setTab("download")}
                class={tab === "download" ? "selected" : ""}
                ><i class="fas fa-file-download"></i> Download</button
            >
            <button
                on:click={() => setTab("delete")}
                class={tab === "delete" ? "selected" : ""}
                ><i class="fas fa-eraser"></i> Delete</button
            >
            <button
                on:click={() => setTab("sharex")}
                class={tab === "sharex" ? "selected" : ""}
                ><i class="fas fa-share-square"></i> ShareX</button
            >
            <button
                on:click={() => setTab("api")}
                class={tab === "api" ? "selected" : ""}
                ><i class="fas fa-code"></i> API</button
            >
        </div>
    </div>
</header>

<style type="text/scss">header {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  grid-template-columns: 25vw auto 25vw;
  display: grid;
  background-color: #ee8572;
  color: #35495e;
}
header div.header-container {
  grid-column-start: 2;
  grid-column-end: 3;
  display: grid;
  grid-template-columns: auto auto;
  align-self: stretch;
  align-self: center;
  padding: 0;
  margin: 0;
}
header div.header-container * {
  color: #35495e;
  margin: 0;
  align-self: center;
  text-decoration: none;
}
header div.header-container div.buttons {
  justify-self: right;
}
header div.header-container div.buttons button {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 1em;
  font-size: 12pt;
  font-weight: bold;
}
header div.header-container div.buttons button:hover, header div.header-container div.buttons button.selected, header div.header-container div.buttons button:hover > i, header div.header-container div.buttons button.selected > i {
  background-color: #35495e;
  color: #ee8572;
  cursor: pointer;
}

/*# sourceMappingURL=Header.svelte.css.map */</style>
