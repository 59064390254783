<script></script>

<h1><i class="fas fa-share-square"></i>&nbsp;&nbsp;ShareX</h1>

<main>
    ShareX custom uploader config: <a href="sharex.sxcu"
        >{window.origin}/sharex.sxcu</a
    >
</main>

<style type="text/scss">h1,
a {
  color: #ee8572;
}

main {
  color: #fff;
}

/*# sourceMappingURL=ShareX.svelte.css.map */</style>
