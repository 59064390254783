<script>
    let version = document.querySelector('meta[name=version]').content;
</script>

<footer>
    <p>
        Author: <a href="https://github.com/olback">olback</a>
        <span class="spacer"></span>
        Version: <a href="https://github.com/olback/tempfiles-rs" title={version}>{version.substring(0, 7)}</a>
    </p>
</footer>

<style type="text/scss">footer {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 3;
  align-self: stretch;
  justify-items: center;
  justify-content: center;
  display: grid;
  padding: 1rem;
  border-bottom: solid 5px #ee8572;
}
footer p, footer a {
  color: #ee8572;
  align-self: center;
  padding: 0;
  margin: 0;
  transition: color 0.1s ease-in-out;
}
footer a:hover {
  color: rgb(232.5126582278, 93.6202531646, 68.4873417722);
}
footer span.spacer {
  font-size: 12pt;
  display: inline-block;
  width: 1rem;
}

/*# sourceMappingURL=Footer.svelte.css.map */</style>
