<script></script>

<h1><i class="fas fa-code"></i>&nbsp;&nbsp;Api</h1>

<main>
    <section>
        <h2>Download</h2>

        <div class="url">
            <span class="method">GET</span>
            <span class="url">/d/&lt;id&gt;/&lt;password&gt;</span>
        </div>
    </section>

    <section>
        <h2>Upload</h2>

        <div class="url">
            <span class="method">POST</span>
            <span class="url">/api/upload</span>
        </div>

        <div class="params">
            <div class="param">
                <span class="name">filename</span>
                <span class="datatype">string (optional)</span>
                <span class="description">Set a filename</span>
            </div>
            <div class="param">
                <span class="name">maxviews</span>
                <span class="datatype">integer</span>
                <span class="description"
                    >Maximum times a file can be viewed</span
                >
            </div>
        </div>

        <div class="headers">
            <div class="header">
                <span class="name">Content-Type</span>
                <span class="description">Set the content type</span>
            </div>
        </div>

        <div class="body">Body: Raw file data</div>

        <code class="response">
            <pre>
&#123;
    "status": 201,
    "id": "d5mSqMV5VzcCm4Jy",
    "password": "Sn86BrP99kghljLK9bavY1DLwjuvN8rL",
    "delete_password": "KX0mQpVJPyyI8WNld7OsgifOTOxc8AiE",
    "download_url": "..."
&#125;
            </pre>
        </code>
    </section>

    <section>
        <h2>Metadata</h2>

        <div class="url">
            <span class="method">GET</span>
            <span class="url">/api/metadata/&lt;id&gt;/&lt;password&gt;</span>
        </div>

        <code class="response">
            <pre>
&#123;
    "status": 200,
    "content_type": "audio/ogg",
    "content_length": 118118,
    "filename": "En-au-oof.ogg"
&#125;
            </pre>
        </code>
    </section>

    <section>
        <h2>Delete</h2>

        <div class="url">
            <span class="method">DELETE</span>
            <span class="url"
                >/api/delete/&lt;id&gt;/&lt;deletion_password&gt;</span
            >
        </div>

        <code class="response">
            <pre>
&#123;
    "status": 200
&#125;
            </pre>
        </code>
    </section>
</main>

<style type="text/scss">h1 {
  color: #ee8572;
}

main {
  display: grid;
  row-gap: 2em;
}

section {
  color: #fff;
  font-size: 12pt;
  display: grid;
  row-gap: 1em;
}
section h2 {
  color: #ee8572;
  margin: 0;
}
section div.url span.method {
  background-color: rgb(34.612244898, 47.6734693878, 61.387755102);
  padding: 1px 2px;
  margin-right: 2px;
  border: solid #ddd 1px;
  border-radius: 3px;
  font-family: monospace;
}
section div.url span.url {
  font-family: monospace;
}
section div.params,
section div.headers {
  display: grid;
  row-gap: 0.2em;
}
section div.params::before,
section div.headers::before {
  margin-bottom: 0.5em;
  display: block;
  content: "Params:";
  font-family: monospace;
}
section div.params div.param span,
section div.params div.header span,
section div.headers div.param span,
section div.headers div.header span {
  display: inline-block;
  margin-right: 0.5em;
}
section div.params div.param span.name,
section div.params div.header span.name,
section div.headers div.param span.name,
section div.headers div.header span.name {
  background-color: #35495e;
  padding: 1px 2px;
  border: solid rgb(34.612244898, 47.6734693878, 61.387755102) 1px;
  border-radius: 3px;
  font-family: monospace;
}
section div.params div.param span.datatype,
section div.params div.header span.datatype,
section div.headers div.param span.datatype,
section div.headers div.header span.datatype {
  font-style: italic;
  font-family: monospace;
}
section div.headers::before {
  content: "Headers:";
}
section div.body {
  font-family: monospace;
}
section code.response {
  font-family: monospace;
}
section code.response::before {
  margin-bottom: 0.5em;
  display: block;
  content: "Response:";
}
section code.response pre {
  font-family: monospace;
  padding: 1em;
  margin: 0;
  background-color: #fff;
  color: #35495e;
}

/*# sourceMappingURL=Api.svelte.css.map */</style>
